
import React from 'react'
import { Ratio, Row, Col, Button } from 'react-bootstrap'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const getPage = items => {
    let pageArray = []
    for (let i = 1; i < (items / 12); i++) {
        pageArray.push(i)
    }
    return pageArray
}



class ProductArchive extends React.Component {
    state = {
        x: 0,
        y: 12,
        pagination: getPage(this.props.products.length + 12),
    }

    moreItems() {
        this.setState((state) => {
            return { y: state.y + 12, x: state.x + 12 }
        })
        window.scroll(0, 0)
    }

    lessItems() {
        this.setState((state) => {
            return { y: state.y - 12, x: state.x - 12 }
        })
        window.scroll(0, 0)
    }

    changePage(key) {
        this.setState((state) => {
            return { y: (key * 12) + 12, x: (key * 12) }
        })
        window.scroll(0, 0)
    }

    render() {
        const isFirst = this.state.x === 0
        const isLast = this.state.y > this.props.products.length
        const needsPages = this.props.products.length > 12
        return (
            <Row className={`my-4`}>
                <h3 className={`fw-light`}>{this.props.widgetTitle} <span className={`text-muted fw-light`}>({this.props.products.length})</span></h3>
                <Row>
                    {this.props.products.slice(this.state.x, this.state.y).map(product => {
                        let productImage
                        if (product.image) {
                            productImage = getImage(product.image.localFile)
                        } else if (this.props.placeholder) {
                            productImage = getImage(this.props.placeholder)
                        }

                        return (
                            <Col md={4} className={`p-4`} >
                                <Link className={`text-dark text-decoration-none`} to={`/product/${product.slug}`}>
                                    <div>
                                        <Ratio aspectRatio="1x1" className={`mb-3 d-flex`}>
                                            <GatsbyImage image={productImage}
                                                objectFit="cover"
                                                objectPosition="50% 50%"
                                                alt="Testing image"
                                                className={`position-absolute`} />
                                        </Ratio>
                                        <div>
                                            <h5 className={`fw-light`}>{product.name}</h5>
                                            {product.salePrice &&
                                                <>
                                                    <span className={`text-danger`}>{product.salePrice}</span> <span className={`text-muted mx-4 fw-light`}>{product.regularPrice}</span>
                                                </>
                                            }
                                            {!product.salePrice &&
                                                <>
                                                    <span>{product.price}</span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
                <div className={`d-flex justify-content-end flex-row`}>
                <div>
                        {!isFirst &&
                            <Button variant="dark" className={`fw-bold text-uppercase rounded-0 ms-2`} key={`less`} onClick={() => this.lessItems()}>
                                Previous
                            </Button>}

                        {needsPages && this.state.pagination.length < 7 &&
                            this.state.pagination.map((page, index) => {
                                return (
                                    <Button variant="dark" className={`fw-bold text-uppercase rounded-0 ms-2`} value={page} key={index} onClick={() => this.changePage(index)}>
                                        {page}
                                    </Button>
                                )
                            })}
                        {needsPages && this.state.pagination.length > 7 &&
                            <>
                            {this.state.pagination.slice(0,3).map((page, index) => {
                                return (
                                    <Button variant="dark" className={`fw-bold text-uppercase rounded-0 ms-2`} value={page} key={index} onClick={() => this.changePage(index)}>
                                        {page}
                                    </Button>
                                )
                            })}
                            <Button variant="dark" className={`fw-bold text-uppercase rounded-0 ms-2`}>
                                ...
                            </Button>
                            {this.state.pagination.slice(this.state.pagination.length - 3,this.state.pagination.length).map((page, index) => {
                                return (
                                    <Button variant="dark" className={`fw-bold text-uppercase rounded-0 ms-2`} value={page} key={page} onClick={() => this.changePage(page - 1)}>
                                        {page}
                                    </Button>
                                )
                            })}
                            </>
                            }
                        {!isLast &&
                            <Button variant="dark" className={`fw-bold text-uppercase rounded-0 ms-2`} type="button" key={`more`} onClick={() => this.moreItems()}>
                                Next
                            </Button>}
                            </div>    
                </div>
            </Row>
        )
    }
}
export default ProductArchive